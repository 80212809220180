import React from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { formatCityStateZip, formatStreetAddress } from '@hcs/utils';

import { DisplayProps } from '.';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  return (
    <>
      <div data-hc-name={`${dataHcName}-street-address`}>
        {formatStreetAddress({
          address: orderItem?.address,
          unit: orderItem?.unit,
        })}
      </div>
      <div data-hc-name={`${dataHcName}-city-state-zip`}>
        {formatCityStateZip({
          city: orderItem?.city,
          state: orderItem?.state,
          zipcode: orderItem?.zipcode,
        })}
      </div>
    </>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Address</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_ADDRESS_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Address',
};
