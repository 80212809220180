import React from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  APP_CONFIG_AGILE_OPS_COMMAND,
  VIEW_PATHS_AGILE_OPS_COMMAND,
} from '@hcs/hc-internal-apps';
import { OmAdminOrderItem } from '@hcs/order-manager-admin';
import { OrderItemStatus } from '@hcs/types';

import { GoBackNav } from '../../navigation/AgileOpsNav/GoBackNav';

export const AgileOpsOrderItemPage = () => {
  const { orderItemId } = useParams();
  const orderItemIdInt = Number(orderItemId);
  const navigate = useNavigate();

  return (
    <>
      {
        <OmAdminOrderItem
          orderItemId={orderItemIdInt}
          backButton={<GoBackNav orderItemId={orderItemIdInt} />}
          onRevisionSuccess={() => {
            navigate(
              `${APP_CONFIG_AGILE_OPS_COMMAND.rootPath}/${VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE}`
            );
          }}
          approveOrderItemProps={{
            onSuccess: (orderItems) => {
              // No review queue navigation if any approval is in inspection review for further approvals
              if (
                !orderItems.some(
                  (orderItem) =>
                    orderItem.status ===
                      OrderItemStatus.InspectionCorrectionReview ||
                    orderItem.status === OrderItemStatus.InspectionReview
                )
              ) {
                navigate(
                  `${APP_CONFIG_AGILE_OPS_COMMAND.rootPath}/${VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE}`
                );
              }
            },
          }}
        />
      }
    </>
  );
};
