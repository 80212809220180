import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Package, SetupIntentKeys } from '@hcs/types';
import { logException } from '@hcs/utils';

import { SelfServiceApi } from '../api';

import { QUERY_KEY_SELF_SERVICE_PAYMENT_METHOD } from './useSelfServicePaymentMethod';

const QUERY_KEY_SELF_SERVICE_SETUP_INTENT = 'self-service-setup-intent';
export const useSelfServiceSetupIntent = (packageId?: Package['id']) => {
  return useQuery(
    [QUERY_KEY_SELF_SERVICE_SETUP_INTENT, packageId],
    async () => {
      // should never happen b/c of enabled option
      if (!packageId) {
        const error = new Error(
          'useSelfServiceSetupIntent: packageId undefined'
        );
        logException(error);
        throw error;
      }
      return await SelfServiceApi.setupIntent(packageId);
    },
    {
      enabled: packageId !== undefined,
    }
  );
};

const REFETCH_INTERVAL = 1500;
const QUERY_KEY_SELF_SERVICE_SETUP_INTENT_WEBHOOK =
  'self-service-setup-intent-webhook';
export const useSelfServiceSetupIntentWebhook = ({
  clientSecret,
  enabled,
}: {
  clientSecret?: SetupIntentKeys['clientSecret'];
  enabled: boolean;
}) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QUERY_KEY_SELF_SERVICE_SETUP_INTENT_WEBHOOK, clientSecret],
    async () => {
      // should never happen b/c of enabled option
      if (!clientSecret) {
        const error = new Error(
          'useSelfServiceSetupIntentWebhook: clientSecret undefined'
        );
        logException(error);
        throw error;
      }
      const response = await SelfServiceApi.setupIntentCheckWebhook(
        clientSecret
      );
      if (response.status !== 'succeeded') {
        return 'shouldRefetch';
      } else {
        // reload payment methods since there should be a new one
        queryClient.invalidateQueries([QUERY_KEY_SELF_SERVICE_PAYMENT_METHOD]);
        return response;
      }
    },
    {
      enabled: enabled && clientSecret !== undefined,
      refetchInterval: (data) => {
        if (data === 'shouldRefetch') {
          return REFETCH_INTERVAL;
        }
        return false;
      },
    }
  );
};
